var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide relative",class:{ 'swiper-slide-login': _vm.isAuthenticated }},[_c('nuxt-link',{staticClass:"image-link",attrs:{"to":_vm.localePath(_vm.getProductSlug(_vm.product))}},[_c('picture',{staticClass:"image w-full block"},[_c('source',{attrs:{"media":"(min-width: 767px)","srcset":_vm.getProductThumbnailImage(_vm.product)
            ? _vm.getProductThumbnailImage(_vm.product)
            : '/icons/placeholder-list.svg'}}),_vm._v(" "),_c('img',{staticClass:"w-full h-auto inset absolute",class:_vm.getProductThumbnailImage(_vm.product) === null
            ? 'sf-image--placeholder'
            : '',attrs:{"src":_vm.getProductThumbnailImage(_vm.product)
            ? _vm.getProductThumbnailImage(_vm.product)
            : '/icons/placeholder-list.svg',"alt":_vm.product.name,"width":"300","height":"200"}})])]),_vm._v(" "),_c('picture',{staticClass:"tag-symbol"},[_c('source',{attrs:{"media":"(min-width: 767px)","srcset":_vm.icon && _vm.icon.desktop ? _vm.icon.desktop : '/homepage/star-icon.svg'}}),_vm._v(" "),_c('img',{class:_vm.getProductThumbnailImage(_vm.product) === null
          ? 'sf-image--placeholder'
          : '',attrs:{"src":_vm.icon && _vm.icon.mobile ? _vm.icon.mobile : '/homepage/star-icon.svg',"alt":"icon star","width":"25","height":"25"}})]),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.product.name))]),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"product-slider__item__price",class:{ 'margin-top-auto': _vm.isAuthenticated }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.npp),expression:"npp"}],staticClass:"product-slider__item__price__npp"}),_vm._v(" "),(_vm.isAuthenticated)?_c('SpecialPrice',{attrs:{"regular":_vm.getRegularPrice(_vm.product),"special":null}}):_vm._e()],1),_vm._v(" "),(_vm.stockStatus)?_c('div',{staticClass:"stock-status",class:{ 'margin-top-auto': !_vm.isAuthenticated }},[_c('div',{staticClass:"stock-status__dot",class:_vm.colorClass(_vm.stockStatus)}),_vm._v(" "),_c('div',{staticClass:"stock-status__label"},[_vm._v(_vm._s(_vm.stockStatus))])]):_vm._e()]),_vm._v(" "),(!_vm.isAuthenticated)?_c('nuxt-link',{staticClass:"arrow-link",attrs:{"to":_vm.localePath(_vm.getProductSlug(_vm.product))}},[_c('nuxt-img',{attrs:{"src":"/homepage/info-icon.svg","alt":"arrow"}}),_vm._v("\n    "+_vm._s(_vm.$t('Request INFO'))+"\n    "),_c('nuxt-img',{attrs:{"src":"/homepage/arrow-icon.svg","alt":"arrow"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }