<template>
  <SfSidebar
    class="wishlist-sidebar"
    :visible="isWishlistSidebarOpen"
    title="I tuoi preferiti"
    @close="toggleWishlistSidebar"
    position="right"
  >
    <template #circle-icon="{ close }">
      <div class="wishlist-sidebar__top-right" @click="close">
        <div
          class="wishlist-sidebar__top-right__new"
          @click="toggleCreateWishlistModal"
        >
          <PlusIcon
            class="wishlist-sidebar__top-right__new__icon"
            v-if="isAuthenticated"
          />
          <span
            class="wishlist-sidebar__top-right__new__label"
            v-if="isAuthenticated"
          >
            Nuova lista preferiti
          </span>
        </div>
        <Cross @click="close" />
      </div>
    </template>
    <template #content-top>
      <div class="wishlist-sidebar__top" v-if="isAuthenticated">
        <CustomRichSelect
          class="wishlist-sidebar__top__selector"
          select-name="wishlist-sidebar-selector"
          select-title="Seleziona una lista preferiti"
          black-outline
          specific-width="100%"
          :select-options="selectableUserWishlists"
          :selected-value="(wishlist && wishlist.uid) || null"
          :reload-options="loadUserWishlists"
          @selectChange="(w) => handleWishlistChange(w)"
          v-if="selectableUserWishlists.length > 0 && !loading"
          :key="`wishlist-sidebar-selector-${
            (wishlist && wishlist.uid) || Date.now()
          }`"
        />
        <CustomInput
          v-model="searchTerm"
          class="wishlist-sidebar__top__search"
          input-name="wishlist-sidebar-search"
          input-id="wishlist-sidebar-search"
          input-placeholder="Nome/SKU prodotto..."
          input-label="Cerca nei preferiti"
          :override-style="{ width: '100%' }"
        />
      </div>
    </template>
    <LoadingDots v-if="loading" />
    <div
      v-if="
        isAuthenticated &&
        !loading &&
        wishlist &&
        wishlist.items &&
        wishlist.items.items.length > 0
      "
      class="wishlist-sidebar__items"
    >
      <WishlistSidebarItem
        v-for="(wishlistItem, wishlistItemIndex) in filteredWishlistItems"
        :key="`wishlist-sidebar-${wishlist.uid}-item-${wishlistItemIndex}-${wishlistItem.uid}`"
        :product="wishlistItem.product"
        :wishlist-item-uid="wishlistItem.uid"
        :wishlist-item-grn="wishlistItem.item_grn"
      />
    </div>
    <div v-if="!isAuthenticated && !loading" class="wishlist-sidebar__empty">
      Effettua l'accesso per visualizzare i tuoi prodotti preferiti
      <CustomButton @click="toggleLoginAndRegisterModal"> Accedi </CustomButton>
    </div>
  </SfSidebar>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import { useUiState, useUserWishlists } from '~/composables';
import {
  CustomSelect,
  CustomInput,
  CustomButton,
  LoadingDots,
} from '~/components/General/FormElements';
import { Cross, PlusIcon } from '~/components/General/Icons';
import CustomRichSelect from '~/components/Sidebars/WishlistsSelect.vue';
import { WishlistSidebarItem } from './WishlistSidebarInternal';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    CustomRichSelect,
    SfSidebar,
    Cross,
    WishlistSidebarItem,
    CustomInput,
    PlusIcon,
    CustomButton,
    LoadingDots,
  },
  props: {},
  setup() {
    const {
      isWishlistSidebarOpen,
      toggleWishlistSidebar,
      toggleCreateWishlistModal,
      toggleLoginAndRegisterModal,
    } = useUiState();
    const searchTerm = ref();

    const {
      userWishlists,
      wishlist,
      changeWishlist,
      selectableUserWishlists,
      loading,
      loadWishlist,
      loadUserWishlists,
      isAuthenticated,
    } = useUserWishlists();

    onMounted(async () => {
      if (!userWishlists.value || !wishlist.value) {
        await loadWishlist();
      }
    });

    const filteredWishlistItems = computed(() => {
      if (!wishlist?.value || !wishlist?.value?.items?.items || loading.value)
        return [];
      if (!searchTerm.value || searchTerm?.value?.length === 0)
        return wishlist.value.items.items.filter(Boolean);
      return wishlist.value.items.items
        .filter(
          (item) =>
            item.product.name
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()) ||
            item.product.merchant_sku
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase())
        )
        .filter(Boolean);
    });

    const handleWishlistChange = async (w) => {
      await changeWishlist(w);
    };

    return {
      isWishlistSidebarOpen,
      toggleWishlistSidebar,
      handleWishlistChange,
      userWishlists,
      selectableUserWishlists,
      wishlist,
      searchTerm,
      filteredWishlistItems,
      loading,
      toggleCreateWishlistModal,
      isAuthenticated,
      toggleLoginAndRegisterModal,
      loadUserWishlists,
    };
  },
});
</script>

<style lang="scss">
.wishlist-sidebar {
  --sidebar-z-index: 3;
  --overlay-z-index: 3;
  .wishlist-sidebar__selector {
    margin-top: 1rem;
  }
  .sf-sidebar {
    &__content {
      padding-top: 0;
    }
    &__aside {
      .sf-bar.smartphone-only {
        display: none;
      }
    }
  }
  &__top-right {
    @include pointer;
    @include flex-center;
    gap: 2rem;
    padding: 1rem 1rem 0 1rem;
    justify-content: space-between;
    &__new {
      @include flex-center;
      gap: 0.5rem;

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--c-primary);
        &__icon {
          scale: 1.5;
        }
      }
      &__label {
        color: var(--c-primary);
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
  &__top {
    z-index: 1;
    position: relative;
    &__title {
      @include heading-h3;
    }
  }
  &__empty {
    @include text-20x26-regular;
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
@include from-tablet-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__top {
        .sf-heading {
          &__title.h3 {
            font-size: 1.5vw;
          }
        }
      }
    }
    &__top-right {
      position: absolute;
      right: 2rem;
      top: 1.6rem;
      padding: 0;
      &__new {
        &__label {
          font-size: 1.1vw;
        }
      }
    }
    &__top {
      margin-top: 2rem;
    }
  }
}
@include from-desktop-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__aside {
        width: 30vw;
      }
    }
    &__top-right {
      top: 2rem;
    }
  }
}
</style>
